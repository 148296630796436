<mat-sidenav-container *ngIf="page_type === 'diamond' || page_type === 'paisaexch'" fullscreen>
  <mat-sidenav-content>
    <app-header></app-header>
    <ng-container *ngIf="page_type === 'diamond'">
      <app-footer *ngIf="!deviceInfo"></app-footer>

      <div class="main-content">
        <div *ngIf="!deviceInfo && casinoStatus" class="tab-content">
          <div id="home" class="tab-pane sports active">
            <ul class="nav nav-tabs game-nav-bar">
              <li class="nav-item text-center" *ngIf="cricket_menu">
                <a data-toggle="tab" (click)="cricket_fun()" [ngClass]="{ active: var_cricket === true }"
                  class="sports nav-link">
                  <div>
                    <img src="assets/images/game-icon/4.png" alt="img" />
                  </div>
                  <div>Cricket</div>
                </a>
              </li>

              <li class="nav-item text-center" *ngIf="soccer_menu">
                <a data-toggle="tab" (click)="football_fun()" [ngClass]="{ active: var_football === true }"
                  class="sports nav-link">
                  <div>
                    <img src="assets/images/game-icon/1.png" alt="img" />
                  </div>
                  <div>Football</div>
                </a>
              </li>

              <li class="nav-item text-center" *ngIf="tennis_menu">
                <a data-toggle="tab" (click)="tennis_fun()" [ngClass]="{ active: var_tennis === true }"
                  class="sports nav-link">
                  <div>
                    <img src="assets/images/game-icon/2.png" alt="img" />
                  </div>
                  <div>Tennis</div>
                </a>
              </li>

              <li class="nav-item text-center">
                <a data-toggle="tab" class="sports nav-link">
                  <div>
                    <img src="assets/images/game-icon/horserace.svg" alt="img" />
                  </div>
                  <div>Horse Racing</div>
                </a>
              </li>

              <li class="nav-item text-center">
                <a data-toggle="tab" class="sports nav-link">
                  <div>
                    <img src="assets/images/game-icon/grayhound.svg" alt="img" />
                  </div>
                  <div>Greyhound Racing</div>
                </a>
              </li>

              <li class="nav-item text-center">
                <a data-toggle="tab" class="sports nav-link">
                  <div>
                    <img src="assets/images/game-icon/kabbdi.png" alt="img" />
                  </div>
                  <div>Kabaddi</div>
                </a>
              </li>

              <li class="nav-item text-center">
                <a data-toggle="tab" class="sports nav-link">
                  <div>
                    <img src="assets/images/game-icon/vollyball.svg" alt="img" />
                  </div>
                  <div>Politics</div>
                </a>
              </li>

              <li class="nav-item text-center">
                <a data-toggle="tab" class="sports nav-link">
                  <div>
                    <img src="https://my99exch.com/assets/img/99990.png" alt="img" />
                  </div>
                  <div>Binary</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- <div *ngIf="marquetext" class="marquee-text">
                <mat-icon> rss_feed</mat-icon>
                <marquee width="100%" direction="left">{{marquetext}}</marquee>
            </div> -->

        <!-- <div id="demo" class="carousel slide my-2" data-ride="carousel"> -->
        <!-- Indicators -->
        <!-- <ul class="carousel-indicators">
                  <li data-target="#demo" data-slide-to="0" class="active"></li>
                  <li data-target="#demo" data-slide-to="1"></li>
                  <li data-target="#demo" data-slide-to="2"></li>
                  <li data-target="#demo" data-slide-to="3"></li>
                  <li data-target="#demo" data-slide-to="4"></li>
                  <li data-target="#demo" data-slide-to="5"></li>
                </ul> -->

        <!-- The slideshow -->
        <!-- <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="https://kushubmedia.com/build/new-osg-app/slider/1.png" alt="Los Angeles" height="180">
                  </div>
                  <div class="carousel-item">
                    <img src="https://kushubmedia.com/build/new-osg-app/slider/2.png" alt="Chicago" height="180">
                  </div>
                  <div class="carousel-item">
                    <img src="https://kushubmedia.com/build/new-osg-app/slider/3.png" alt="New York" height="180">
                  </div>
                  <div class="carousel-item">
                    <img src="https://kushubmedia.com/build/new-osg-app/slider/4.png" alt="Los Angeles" height="180">
                  </div>
                  <div class="carousel-item">
                    <img src="https://kushubmedia.com/build/new-osg-app/slider/5.png" alt="Chicago" height="180">
                  </div>
                  <div class="carousel-item">
                    <img src="https://kushubmedia.com/build/new-osg-app/slider/6.png" alt="New York" height="180">
                  </div>
                </div> -->

        <!-- Left and right controls -->
        <!-- <a class="carousel-control-prev" data-slide="prev">
                  <span class="carousel-control-prev-icon"></span>
                </a>
                <a class="carousel-control-next" data-slide="next">
                  <span class="carousel-control-next-icon"></span>
                </a>
              </div> -->

        <!--//////////////////Desktop Start////////////////////////-->
        <!--//////////////////Desktop Start////////////////////////-->
        <div class="desktopmain-main">
          <!-- desktop_header -->
          <app-desk-header></app-desk-header>
          <!-- desktop_header_end -->
          <div class="container-fluid container-fluid-5">
            <div class="row row5">
              <!--desktop_sidebar_others  -->
              <div class="sidebar col-md-2">
                <app-desk-sidebar></app-desk-sidebar>
              </div>

              <!--flterTabsGrey_dashboard-->
              <div class="col-md-10 featured-box">
                <div>
                  <!--flterTabsandMatchDetails-->
                  <div class="pb-4">
                    <div class="container-fluid d-flex p-0" style="overflow-x: auto;" *ngIf="homeInplayData?.length>0">
                      <div class="latest-event-item" *ngFor="let x of homeInplayData;trackBy: trackByFunction">
                        <a href="javascript:void(0)" (click)="matchDetail(x.eventId,x.eventTypeId)">
                          <img *ngIf="x.eventTypeId==4 || x.eventTypeId=='v9'"
                            src="../../assets/images/game-icon/cricketBall.png" alt="">
                          <img *ngIf="x.eventTypeId==1" src="../../assets/images/game-icon/football.png" alt="">
                          <img *ngIf="x.eventTypeId==2" src="../../assets/images/game-icon/2.svg" alt="">
                          <span>{{x?.eventName}}</span>
                        </a>
                      </div>
                    </div>
                    <ul role="tablist" id="home-events" class="nav nav-tabs">
                      <li (click)="home_inplay_fun()" class="nav-item">
                        <a href="javascript:void(0)" data-toggle="tab" class="nav-link active">Inplay</a>
                      </li>
                      <li *ngIf="cricket_menu" (click)="cricket_fun()" class="nav-item">
                        <a href="javascript:void(0)" data-toggle="tab" class="nav-link">Cricket</a>
                      </li>
                      <li *ngIf="soccer_menu" (click)="football_fun()" class="nav-item">
                        <a href="javascript:void(0)" data-toggle="tab" class="nav-link">Football</a>
                      </li>
                      <li *ngIf="tennis_menu" (click)="tennis_fun()" class="nav-item">
                        <a href="javascript:void(0)" data-toggle="tab" class="nav-link">Tennis</a>
                      </li>
                      <li class="nav-item">
                        <a href="javascript:void(0)" data-toggle="tab" class="nav-link">Horse Racing</a>
                      </li>
                      <li class="nav-item">
                        <a href="javascript:void(0)" data-toggle="tab" class="nav-link">Greyhound Racing</a>
                      </li>
                      <li class="nav-item">
                        <a href="javascript:void(0)" data-toggle="tab" class="nav-link">Kabbadi</a>
                      </li>
                      <li class="nav-item">
                        <a href="javascript:void(0)" data-toggle="tab" class="nav-link">Politics</a>
                      </li>
                      <li class="nav-item">
                        <a href="javascript:void(0)" data-toggle="tab" class="nav-link">Casino</a>
                      </li>
                      <li *ngIf="casino_menu" [routerLink]="['/slotgame']" class="nav-item">
                        <a href="javascript:void(0)" data-toggle="tab" class="nav-link">Int Casino</a>
                      </li>
                      <li class="nav-item">
                        <a href="javascript:void(0)" data-toggle="tab" class="nav-link">Binary</a>
                      </li>

                      <!-- <li class="nav-item">
                        <a class="nav-link">Horse Racing</a>
                      </li>
                      <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">Table Tennis</a>
                      </li>
                      <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">Basketball</a>
                      </li>
                      <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">Volleyball</a>
                      </li>
                      <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">Kabaddi</a>
                      </li>
                      <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">Ice Hockey</a>
                      </li>
                      <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">Futsal</a>
                      </li>
                      <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">E Games</a>
                      </li>
                      <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">Snooker</a>
                      </li> -->
                    </ul>
                    <div class="tab-content">
                      <div class="tab-pane active">
                        <div class="coupon-card coupon-card-first">
                          <div class="card-content">
                            <table class="table coupon-table">
                              <thead>
                                <tr>
                                  <th style="width: 63%">Game</th>
                                  <th colspan="2">1</th>
                                  <th colspan="2">X</th>
                                  <th colspan="2">2</th>
                                </tr>
                              </thead>
                              <tbody>

                                <ng-container *ngIf="var_cricket">
                                  <!-- virtual -->
                                  <ng-container *ngIf="virtualCricketData?.length">
                                    <tr style="cursor: pointer" *ngFor="let x of virtualCricketData"
                                      (click)="virtualDetial(x.eventId)">
                                      <td>
                                        <div class="game-name">
                                          <span class="sport-icon">
                                            <img src="../../assets/images/game-icon/cricketBall.png" alt="">
                                          </span>
                                          <a class="text-dark">
                                            {{ x?.eventName }} /
                                            {{
                                            moment(x?.openDate)
                                            .utcOffset("+05:30")
                                            .format("MMM D YYYY h:mm:ss A")
                                            }}
                                          </a>
                                        </div>
                                        <div class="game-icons">
                                          <span *ngIf="currentDate > x.openDate" class="game-icon"><span
                                              class="active"></span></span>
                                          <span class="game-icon">
                                            <!---->
                                          </span>
                                          <span class="game-icon">V</span>
                                        </div>
                                      </td>
                                      <!---->
                                      <td>
                                        <button class="back">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[0]
                                            ?.availableToBack?.price
                                            }}</span>
                                        </button>
                                      </td>
                                      <!----><!---->
                                      <td>
                                        <button class="lay">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToLay?.price
                                            }}</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="back">
                                          <span class="odd">-</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="lay">
                                          <span class="odd">-</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="back">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToBack?.price
                                            }}</span>
                                        </button>
                                      </td>
                                      <!----><!---->
                                      <td>
                                        <button class="lay">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToLay?.price
                                            }}</span>
                                        </button>
                                      </td>
                                    </tr>
                                  </ng-container>

                                  <!-- Ball by ball -->
                                  <ng-container *ngIf="ballbyballData?.length && ballbyball_menu">
                                    <tr style="cursor: pointer" *ngFor="let x of ballbyballData"
                                      (click)="ballbyballDetail(x.eventId)">
                                      <td>
                                        <div class="game-name blink_ballbyball">
                                          <span class="sport-icon">
                                            <img src="../../assets/images/game-icon/cricketBall.png" alt="">
                                          </span>
                                          <a class="text-dark">
                                            {{ x?.eventName }} /
                                            {{
                                            moment(x?.openDate)
                                            .utcOffset("+05:30")
                                            .format("MMM D YYYY h:mm:ss A")
                                            }}
                                          </a>
                                        </div>
                                        <div class="game-icons">
                                          <span *ngIf="currentDate > x.openDate" class="game-icon"><span
                                              class="active"></span></span>
                                          <span class="game-icon">
                                            <!---->
                                          </span>
                                          <!-- <span class="game-icon">V</span> -->
                                        </div>
                                      </td>
                                      <!---->
                                      <td>
                                        <button class="back">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[0]
                                            ?.availableToBack?.price
                                            }}</span>
                                        </button>
                                      </td>
                                      <!----><!---->
                                      <td>
                                        <button class="lay">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToLay?.price
                                            }}</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="back">
                                          <span class="odd">-</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="lay">
                                          <span class="odd">-</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="back">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToBack?.price
                                            }}</span>
                                        </button>
                                      </td>

                                      <td>
                                        <button class="lay">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToLay?.price
                                            }}</span>
                                        </button>
                                      </td>
                                    </tr>
                                  </ng-container>

                                  <!-- cricket -->
                                  <ng-container *ngIf="cricketData?.length">
                                    <tr style="cursor: pointer" *ngFor="let x of cricketData"
                                      (click)="matchDetail(x.eventId,x.eventTypeId)">
                                      <td>
                                        <div class="game-name">
                                          <span class="sport-icon">
                                            <img src="../../assets/images/game-icon/cricketBall.png" alt="">
                                          </span>
                                          <a class="text-dark">
                                            {{ x?.eventName }} /
                                            {{
                                            moment(x?.openDate)
                                            .utcOffset("+05:30")
                                            .format("MMM D YYYY h:mm:ss A")
                                            }}
                                          </a>
                                        </div>
                                        <div class="game-icons">
                                          <span *ngIf="currentDate > x.openDate" class="game-icon"><span
                                              class="active"></span></span>
                                          <span class="game-icon">
                                            <!---->
                                          </span>
                                          <span class="game-icon"><img
                                              src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/icons/ic_fancy.png"
                                              class="fancy-icon" /></span>
                                          <span class="game-icon"><img
                                              src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/icons/ic_bm.png"
                                              class="bookmaker-icon" /></span>
                                        </div>
                                      </td>
                                      <!---->
                                      <td>
                                        <button class="back">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[0]
                                            ?.availableToBack?.price
                                            }}</span>
                                        </button>
                                      </td>
                                      <!----><!---->
                                      <td>
                                        <button class="lay">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToLay?.price
                                            }}</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="back">
                                          <span class="odd">-</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="lay">
                                          <span class="odd">-</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="back">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToBack?.price
                                            }}</span>
                                        </button>
                                      </td>
                                      <!----><!---->
                                      <td>
                                        <button class="lay">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToLay?.price
                                            }}</span>
                                        </button>
                                      </td>
                                    </tr>
                                  </ng-container>
                                </ng-container>

                                <!-- Soccer -->
                                <ng-container *ngIf="var_football">
                                  <ng-container *ngIf="soccerData?.length">
                                    <tr style="cursor: pointer" *ngFor="let x of soccerData"
                                      (click)="matchDetail(x.eventId,x.eventTypeId)">
                                      <td>
                                        <div class="game-name">
                                          <span class="sport-icon">
                                            <img src="../../assets/images/game-icon/football.png" alt="">
                                          </span>
                                          <a class="text-dark">
                                            {{ x?.eventName }} /
                                            {{
                                            moment(x?.openDate)
                                            .utcOffset("+05:30")
                                            .format("MMM D YYYY h:mm:ss A")
                                            }}
                                          </a>
                                        </div>
                                        <div *ngIf="currentDate > x.openDate" class="game-icons">
                                          <span class="game-icon"><span class="active"></span></span>
                                        </div>
                                      </td>
                                      <!---->
                                      <td>
                                        <button class="back">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[0]
                                            ?.availableToBack?.price
                                            }}</span>
                                        </button>
                                      </td>
                                      <!----><!---->
                                      <td>
                                        <button class="lay">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToLay?.price
                                            }}</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="back">
                                          <span class="odd">-</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="lay">
                                          <span class="odd">-</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="back">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToBack?.price
                                            }}</span>
                                        </button>
                                      </td>
                                      <!----><!---->
                                      <td>
                                        <button class="lay">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToLay?.price
                                            }}</span>
                                        </button>
                                      </td>
                                    </tr>
                                  </ng-container>
                                </ng-container>

                                <!-- Tennis -->
                                <ng-container *ngIf="var_tennis">
                                  <ng-container *ngIf="tennisData?.length">
                                    <tr style="cursor: pointer" *ngFor="let x of tennisData"
                                      (click)="matchDetail(x.eventId,x.eventTypeId)">
                                      <td>
                                        <div class="game-name">
                                          <span class="sport-icon">
                                            <img src="../../assets/images/game-icon/cricketBall.png" alt="">
                                          </span>
                                          <a class="text-dark">
                                            {{ x?.eventName }} /
                                            {{
                                            moment(x?.openDate)
                                            .utcOffset("+05:30")
                                            .format("MMM D YYYY h:mm:ss A")
                                            }}
                                          </a>
                                        </div>
                                        <div *ngIf="currentDate > x.openDate" class="game-icons">
                                          <span class="game-icon"><span class="active"></span></span>
                                        </div>
                                      </td>
                                      <!---->
                                      <td>
                                        <button class="back">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[0]
                                            ?.availableToBack?.price
                                            }}</span>
                                        </button>
                                      </td>
                                      <!----><!---->
                                      <td>
                                        <button class="lay">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToLay?.price
                                            }}</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="back">
                                          <span class="odd">-</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="lay">
                                          <span class="odd">-</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="back">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToBack?.price
                                            }}</span>
                                        </button>
                                      </td>
                                      <!----><!---->
                                      <td>
                                        <button class="lay">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToLay?.price
                                            }}</span>
                                        </button>
                                      </td>
                                    </tr>
                                  </ng-container>
                                </ng-container>

                                <!-- homeInplay -->
                                <ng-container *ngIf="var_homeInplay">

                                  <ng-container *ngIf="homeInplayData?.length">
                                    <tr style="cursor: pointer"
                                      *ngFor="let x of homeInplayData; trackBy: trackByFunction"
                                      (click)="matchDetail(x.eventId,x.eventTypeId)">
                                      <td>
                                        <div class="game-name" [ngClass]="{ blink_ballbyball: x.eventTypeId === 'b9'}">
                                          <span class="sport-icon">
                                            <img *ngIf="x.eventTypeId==4 || x.eventTypeId=='v9' || x.eventTypeId=='b9'"
                                              src="../../assets/images/game-icon/cricketBall.png" alt="">
                                            <img *ngIf="x.eventTypeId==1"
                                              src="../../assets/images/game-icon/football.png" alt="">
                                            <img *ngIf="x.eventTypeId==2" src="../../assets/images/game-icon/2.svg"
                                              alt="">
                                          </span>
                                          <a class="text-dark">
                                            {{ x?.eventName }} /
                                            {{
                                            moment(x?.openDate)
                                            .utcOffset("+05:30")
                                            .format("MMM D YYYY h:mm:ss A")
                                            }}
                                          </a>&nbsp;
                                          <span *ngIf="x?.eventTypeId === 'b9'" class="font-weight-bold badge">
                                            New</span>
                                        </div>
                                        <div *ngIf="currentDate > x.openDate" class="game-icons">
                                          <span *ngIf="x.eventTypeId=='v9'" class="game-icon font-weight-bold">V</span>
                                          <span class="game-icon"><span class="active"></span></span>
                                        </div>
                                      </td>
                                      <!---->
                                      <td>
                                        <button class="back">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[0]
                                            ?.availableToBack?.price
                                            }}</span>
                                        </button>
                                      </td>
                                      <!----><!---->
                                      <td>
                                        <button class="lay">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToLay?.price
                                            }}</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="back">
                                          <span class="odd">-</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="lay">
                                          <span class="odd">-</span>
                                        </button>
                                      </td>
                                      <td>
                                        <button class="back">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToBack?.price
                                            }}</span>
                                        </button>
                                      </td>
                                      <!----><!---->
                                      <td>
                                        <button class="lay">
                                          <span class="odd">{{
                                            x?.marketBook?.runners[1]
                                            ?.availableToLay?.price
                                            }}</span>
                                        </button>
                                      </td>
                                    </tr>
                                  </ng-container>
                                </ng-container>
                              </tbody>
                            </table>

                            <ng-container *ngIf="
                                (soccerData?.length == 0 && var_football) ||
                                (tennisData?.length == 0 && var_tennis) ||
                                (cricketData?.length == 0 &&
                                  virtualCricketData?.length == 0 &&
                                  var_cricket)
                              ">
                              <div style="background-color: #cccccc" class="text-dark text-center p-2">
                                No real-time records found
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- desktop our_casino -->
                  <!--new games-->
                  <div class="new-games-container">
                    <div class="row mx-0">
                      <!-- openCasino('','') -->
                      <!-- openCasino('YGG-theroyalfamily','') -->
                      <div *ngIf="casino_menu" (click)="openCasino('','')" class="col-3 px-1 position-relative"
                        tabindex="0">
                        <img src="../../assets/images/newgames/royalgaming-banner.webp" class="img-fluid">
                        <span class="new_games">new launch</span>
                      </div>
                      <!-- openCasino('','') -->
                      <ng-container *ngIf="live_casino_menu">
                        <div (click)="openCasino('WOO-wolfrush','')" class="col-3 px-1 position-relative" tabindex="0">
                          <img src="../../assets/images/newgames/popular-games-banner.webp" class="img-fluid">
                          <span class="new_games">new launch</span>
                        </div>
                        <!-- (click)="openCasino('','67722-2')" -->
                        <div (click)="openCasino('SPB-aviator','')" class="col-3 px-1 position-relative" tabindex="0">
                          <img src="../../assets/images/newgames/aviator-banner.webp" class="img-fluid">
                        </div>
                        <!-- openCasino('','67630') -->
                        <div (click)="openCasino('EVP-teenpatti','')" class="col-3 px-1 position-relative" tabindex="0">
                          <img src="../../assets/images/newgames/liveteenpatti-banner.webp" class="img-fluid">
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <!--Desktop our_casino casinoImg hide_Qtech-->
                  <div *ngIf="casino_menu" class="home-products-container">
                    <div class="row row5">
                      <div class="col-md-12">
                        <div class="home-products-inner">
                          <a (click)="openCasino('EZU-cricketwar','45100')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/superover.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">Super Over</div> -->
                              <!-- <div class="new-launch-casino">
                                <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/offer-patch.png" />
                              </div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('TRB-ballball','')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/ballbyball.jpg"
                                class="img-fluid" />
                              <!-- <div class="casino-name">7 Up down</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-teenpatti','227101')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teen32.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">Auto Roulette</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-teenpatti','227101')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teen3.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">Teen Patti</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-roulette','541000')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/roulette1.jpg" />
                              <!-- <div class="casino-name">Cricket War</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-threecardpoker','227100')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/3cardj.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">3 Cards Judgement</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('HOG-lobby','')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/lottcard.jpg"
                                class="img-fluid" />
                              <!-- <div class="casino-name">Lottery</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-threecardpoker','227100')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/poker.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">Book Cricket</daiv> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-nocommissionbaccarat', '227103')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teen.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">One Day Teenpatti</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-baccarat','100')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/baccarat2.jpg"
                                class="img-fluid" />
                              <!-- <div class="casino-name">Akbar Romeo Walter</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-teenpatti','227100')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/patti2.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">2 Cards Teenpatti</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-dragontiger','150')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/dt6.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">Dragon Tiger One day</div> -->
                            </div>
                          </a>
                          <!-- <a (click)="openCasino('7ML-dragontiger','')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/dtl20.jpg" class="img-fluid" />
                              <div class="casino-name">Dragon Tiger Lion</div>
                            </div>
                          </a> -->
                          <!-- <a (click)="openCasino('EZU-teenpatti','227100')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/patti2.jpg" class="img-fluid" />
                              <div class="casino-name">2 Card Teen Patti One Day </div>
                            </div>
                          </a> -->
                          <a (click)="openCasino('EZU-casinoholdem','507000')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/dtl20.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">Center Card</div> -->
                            </div>
                          </a>
                          <!-- <a (click)="openCasino('EVO-footballstudio','')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/dt202.jpg" class="img-fluid" />
                              <div class="casino-name">Foot Ball Studio</div>
                            </div>
                          </a> -->
                          <a (click)="openCasino('EZU-andarbahar','228000')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/abj.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">Movie Casino</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-lucky7','228001')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/lucky7eu.jpg"
                                class="img-fluid" />
                              <!-- <div class="casino-name">Side Bet City</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-cricketwar','45100')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/cricketv3.jpg"
                                class="img-fluid" />
                              <!-- <div class="casino-name">2 card 1 day</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-cricketwar','45100')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/cmatch20.jpg"
                                class="img-fluid" />
                              <!-- <div class="casino-name">Center Card 1 Day</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-teenpatti','227101')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teen6.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">Muflis 1 Day</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-lucky7','228001')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/lucky7eu2.jpg"
                                class="img-fluid" />
                              <!-- <div class="casino-name">Super Over 1 day</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-super6baccarat','130')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teensin.jpg"
                                class="img-fluid" />
                              <!-- <div class="casino-name">High Card</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('CQC-firequeen2','')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/queen.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">Queen Race</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-teenpatti','227101')">
                            <div class="d-inline-block casinoicons">
                              <img src="../../assets/images/teen120.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">Royal Gaming Lobby</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('BTG-theracemegaways','')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/race20.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">Race 20</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EVO-dreamcatcher','')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/notenum.jpg"
                                class="img-fluid" />
                              <!-- <div class="casino-name">Dream Catcher</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-roulette','541000')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/roulette.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">ROULETTE</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-sicbo','224000')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/sicbo.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">Sicbo</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-dragontiger','150')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/dt202.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">DRAGON TIGER</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('SAG-teenpatti2020','227101')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teen20.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">TEENPATTI T20</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-baccarat','100')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/baccarat.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">Baccarat</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-lucky7','228001')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/lucky7.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">Lucky 7</div> -->
                            </div>
                          </a>

                          <a (click)="openCasino('EZU-nocommissionbaccarat', '227103')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teen1.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">HIGH LOW</div> -->
                            </div>
                          </a>

                          <!-- <a (click)="openCasino('EVO-videopoker','')">
                            <div class="d-inline-block casinoicons">
                              <img src="../../assets/images/game-icon/rg_poker_vr.webp" class="img-fluid"
                                type="image/webp" />
                              <div class="casino-name">POKER</div>
                            </div>
                          </a> -->
                          <a (click)="openCasino('EZU-teenpatti','227101')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teen9.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">TEENPATTI TEST</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-32cards','228002')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/card32.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">32 CARD CASINO</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-32cards','228002')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/card32eu.jpg"
                                class="img-fluid" />
                              <!-- <div class="casino-name">32 CARD CASINO</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('KGL-deeprush','')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/worli.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">WORLI MATKA</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('BTV-instantlucky7','')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/worli2.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">WORLI MATKA</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-teenpatti','227101')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teen8.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">Teenpatti Open</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-threecardpoker','227100')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/poker20.jpg"
                                class="img-fluid" />
                              <!-- <div class="casino-name">POKER 20-20</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-teenpatti','227101')">
                            <div class="d-inline-block casinoicons">
                              <img src="../../assets/images/teen20b.jpg" class="img-fluid" type="image/webp" />
                              <!-- <div class="casino-name">29 CARD BACCARAT</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-teenpatti','227101')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teenmuf.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">MUFLIS TEENPATTI</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('PPL-nocommspeedbaccarat2','171')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/race17.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">RACE TO 17</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('WOO-tricardpoker','')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/trio.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">TRIO</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('NE-crabtrap','')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/trap.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">THE TRAP</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-andarbahar','228000')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/ab20.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">Andar Bahar</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('BTV-6poker','227200')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/poker6.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">SIX PLAYER POKER</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-casinoholdem','507000')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/cmeter.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">CASINO METER</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-casinoholdem','507000')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/war.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">CASINO WAR</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('NE-bollywoodstory','')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/aaa.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">Amar Akbar Anthony</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-casinoholdem','507000')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/btable.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">Bollywood Casino</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('TPG-andarbahar','')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/ab3.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">Bollywood Casino</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('NE-bollywoodstory','')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/aaa2.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">Bollywood Casino</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('PNG-spacerace','')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/race2.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">Bollywood Casino</div> -->
                            </div>
                          </a>

                          <a (click)="openCasino('YGG-goldenchiproulette','')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/dum10.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">Bollywood Casino</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('WAZ-threecards','')">
                            <div class="d-inline-block casinoicons">
                              <img src="https://nd.sprintstaticdata.com/casino-icons/lc/cmeter1.jpg" class="img-fluid"
                                type="image/webp" />
                              <!-- <div class="casino-name">Bollywood Casino</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-teenpatti','227101')">
                            <div class="d-inline-block casinoicons">
                              <img src="../../assets/images/kbc.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">TEENPATTI TEST</div> -->
                            </div>
                          </a>
                          <a (click)="openCasino('EZU-dragontiger','150')">
                            <div class="d-inline-block casinoicons">
                              <img src="../../assets/images/dragon20.jpg" class="img-fluid" />
                              <!-- <div class="casino-name">TEENPATTI TEST</div> -->
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!--///////////////Desktop end/////////////////////////-->
        <!--///////////////Desktop end/////////////////////////-->

        <div class="dashboard-page" *ngIf="!deviceInfo" [ngStyle]="
            casinoFooter == true
              ? { 'max-height': '210px' }
              : { 'max-height': '590px' }
          " style="overflow: scroll; overflow-x: hidden">
          <!-- virtualCricketData -->
          <!-- <ng-container *ngIf="virtualCricketData?.length">
          <div class="game-list-card"> -->
          <!-- <div class="game-list-header">
              <img src="assets/icons/cricket.png">Virtual Cricket ({{virtualCricketData?.length}})
            </div> -->
          <!-- <mat-card *ngFor="let x of virtualCricketData" class="match-event-list m-1"
              (click)="virtualDetial(x.eventId);"> -->

          <!-- <mat-divider inset></mat-divider> -->
          <!-- <div class="match-name" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                <div class="col-8"> -->
          <!-- <div class="odd-content "> -->
          <!-- <div class="odd-title" style="margin-bottom: 5px;word-spacing: 3px;">
                    <span style="line-height: 23px;font-size: 1rem;font-weight: 600;
                      display: block;">{{x?.eventName}}</span>
                  </div>
                  <div class="date_time">
                    <span>{{"Sep 28, 2022 7:30 pm"}}</span>
                  </div>
                </div>
                <div class="col-3" style="text-align: end;">
                  <span class="green_point"><img src="../../assets/icons/Green-Circle.png" width="10"
                      height="10"></span>
                  <span class="tv_icon"><span class="material-symbols-outlined">
                      tv
                    </span></span>
                  <span class="bw_word"><b><i>BM</i></b></span>
                </div>
              </div>
              <div class="row" style="flex-wrap: nowrap !important;">
                <div class="col-sm-4 text-center">
                  <span style="margin-left: 31px;">1</span>
                </div>
                <div class="col-sm-4 text-center">
                  <span>x</span>
                </div>
                <div class="col-sm-4 text-center">
                  <span style="margin-right: 31px;">2</span>
                </div>
              </div>
              <div class="event-odds" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                <div class="match-rate">
                  <div class="odd-list back-rate back-1">{{x?.marketBook?.runners[0]?.availableToBack?.price}}</div>
                  <div class="odd-list lay-rate lay-1">{{x?.marketBook?.runners[0]?.availableToLay?.price}}</div>
                  <div class="odd-list back-rate back-2">--</div>
                  <div class="odd-list lay-rate lay-2">--</div>
                  <div class="odd-list back-rate back-3">
                    {{x?.marketBook?.runners[1]?.availableToBack?.price}}</div>
                  <div class="odd-list lay-rate lay-3">
                    {{x?.marketBook?.runners[1]?.availableToLay?.price}}</div>
                </div>
              </div>
            </mat-card>
          </div>
        </ng-container> -->

          <!-- Cricket -->
          <ng-container *ngIf="var_cricket">
            <!-- virtualCricketData -->
            <ng-container *ngIf="virtualCricketData?.length">
              <div class="game-list-card">
                <!-- <div class="game-list-header">
              <img src="assets/icons/cricket.png"><span class="text-white">Virtual ({{virtualCricketData?.length}})</span>
            </div> -->
                <mat-card *ngFor="let x of virtualCricketData" class="match-event-list m-1"
                  (click)="virtualDetial(x.eventId)">
                  <div class="match-name" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                    <div class="col-8">
                      <p class="odd-title" style="margin-bottom: 5px">
                        <strong style="
                            line-height: 18px;
                            font-size: 14px;
                            display: block;
                          ">{{ x?.eventName }}</strong>
                      </p>
                      <div class="date_time">
                        <span>{{
                          moment(x?.openDate)
                          .utcOffset("+05:30")
                          .format("MMM D YYYY")
                          }}
                          {{
                          moment(x?.openDate)
                          .utcOffset("+05:30")
                          .format("h:mm A")
                          }}
                          (IST)</span>
                      </div>
                    </div>
                    <div class="col-3" style="text-align: end">
                      <span *ngIf="currentDate > x.openDate" class="green_point"><img
                          src="../../assets/icons/Green-Circle.png" width="10" height="10" /></span>
                      <span class="tv_icon"><i class="fa fa-tv"></i></span>
                      <span class="bw_word text-danger"><b>Virtual</b></span>
                    </div>
                  </div>
                  <div class="row" style="flex-wrap: nowrap !important">
                    <div class="col-sm-4 text-center">
                      <span style="margin-left: 31px; font-weight: 600">1</span>
                    </div>
                    <div class="col-sm-4 text-center">
                      <span style="font-weight: 600">x</span>
                    </div>
                    <div class="col-sm-4 text-center">
                      <span style="margin-right: 31px; font-weight: 600">2</span>
                    </div>
                  </div>
                  <div class="event-odds" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                    <div class="match-rate">
                      <div class="odd-list back-rate back-1">
                        {{ x?.marketBook?.runners[0]?.availableToBack?.price }}
                      </div>
                      <div class="odd-list lay-rate lay-1">
                        {{ x?.marketBook?.runners[0]?.availableToLay?.price }}
                      </div>
                      <div class="odd-list back-rate back-2">--</div>
                      <div class="odd-list lay-rate lay-2">--</div>
                      <div class="odd-list back-rate back-3">
                        {{ x?.marketBook?.runners[1]?.availableToBack?.price }}
                      </div>
                      <div class="odd-list lay-rate lay-3">
                        {{ x?.marketBook?.runners[1]?.availableToLay?.price }}
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </ng-container>

            <!-- virtual_end -->

            <!-- Ball by ball -->
            <ng-container *ngIf="ballbyballData?.length && ballbyball_menu">
              <div class="game-list-card">
                <!-- <div class="game-list-header">
                <img src="assets/icons/cricket.png"><span class="text-white">Virtual ({{virtualCricketData?.length}})</span>
              </div> -->
                <mat-card *ngFor="let x of ballbyballData" class="match-event-list m-1"
                  (click)="ballbyballDetail(x.eventId)">
                  <div class="match-name" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                    <div class="col-8">
                      <p class="odd-title blink_ballbyball" style="margin-bottom: 5px">
                        <strong style="
                              line-height: 18px;
                              font-size: 14px;
                              display: block;
                            ">{{ x?.eventName }} <span class="font-weight-bold badge">
                            New</span></strong>

                      </p>
                      <div class="date_time">
                        <span>{{
                          moment(x?.openDate)
                          .utcOffset("+05:30")
                          .format("MMM D YYYY")
                          }}
                          {{
                          moment(x?.openDate)
                          .utcOffset("+05:30")
                          .format("h:mm A")
                          }}
                          (IST)</span>

                      </div>
                    </div>
                    <div class="col-3" style="text-align: end">
                      <span *ngIf="currentDate > x.openDate" class="green_point"><img
                          src="../../assets/icons/Green-Circle.png" width="10" height="10" /></span>
                      <span class="tv_icon"><i class="fa fa-tv"></i></span>
                      <!-- <span class="bw_word text-danger"><b>Virtual</b></span> -->
                    </div>
                  </div>
                  <div class="row" style="flex-wrap: nowrap !important">
                    <div class="col-sm-4 text-center">
                      <span style="margin-left: 31px; font-weight: 600">1</span>
                    </div>
                    <div class="col-sm-4 text-center">
                      <span style="font-weight: 600">x</span>
                    </div>
                    <div class="col-sm-4 text-center">
                      <span style="margin-right: 31px; font-weight: 600">2</span>
                    </div>
                  </div>
                  <div class="event-odds" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                    <div class="match-rate">
                      <div class="odd-list back-rate back-1">
                        {{ x?.marketBook?.runners[0]?.availableToBack?.price }}
                      </div>
                      <div class="odd-list lay-rate lay-1">
                        {{ x?.marketBook?.runners[0]?.availableToLay?.price }}
                      </div>
                      <div class="odd-list back-rate back-2">--</div>
                      <div class="odd-list lay-rate lay-2">--</div>
                      <div class="odd-list back-rate back-3">
                        {{ x?.marketBook?.runners[1]?.availableToBack?.price }}
                      </div>
                      <div class="odd-list lay-rate lay-3">
                        {{ x?.marketBook?.runners[1]?.availableToLay?.price }}
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </ng-container>

            <!-- Ball by ball end -->

            <!-- cricket -->
            <ng-container *ngIf="cricketData?.length">
              <div class="game-list-card">
                <!-- <div class="game-list-header">
              <img src="assets/icons/cricket.png"><span class="text-white">Cricket ({{cricketData?.length}})</span>
            </div> -->
                <mat-card *ngFor="let x of cricketData" class="match-event-list m-1"
                  (click)="matchDetail(x.eventId,x.eventTypeId)">
                  <div class="match-name" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                    <div class="col-8">
                      <p class="odd-title" style="margin-bottom: 5px">
                        <strong style="
                            line-height: 18px;
                            font-size: 14px;
                            display: block;
                          ">{{ x?.eventName }}</strong>
                      </p>
                      <div class="date_time">
                        <span>{{
                          moment(x?.openDate)
                          .utcOffset("+05:30")
                          .format("MMM D YYYY")
                          }}
                          {{
                          moment(x?.openDate)
                          .utcOffset("+05:30")
                          .format("h:mm A")
                          }}
                          (IST)</span>
                      </div>
                    </div>
                    <div class="col-3" style="text-align: end">
                      <span *ngIf="currentDate > x.openDate" class="green_point"><img
                          src="../../assets/icons/Green-Circle.png" width="10" height="10" /></span>
                      <span class="tv_icon"><i class="fa fa-tv"></i></span>
                      <span class="bw_word"><b><i>BM</i></b></span>
                    </div>
                  </div>
                  <div class="row" style="flex-wrap: nowrap !important">
                    <div class="col-sm-4 text-center">
                      <span style="margin-left: 31px; font-weight: 600">1</span>
                    </div>
                    <div class="col-sm-4 text-center">
                      <span style="font-weight: 600">x</span>
                    </div>
                    <div class="col-sm-4 text-center">
                      <span style="margin-right: 31px; font-weight: 600">2</span>
                    </div>
                  </div>
                  <div class="event-odds" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                    <div class="match-rate">
                      <div class="odd-list back-rate back-1">
                        {{ x?.marketBook?.runners[0]?.availableToBack?.price }}
                      </div>
                      <div class="odd-list lay-rate lay-1">
                        {{ x?.marketBook?.runners[0]?.availableToLay?.price }}
                      </div>
                      <div class="odd-list back-rate back-2">--</div>
                      <div class="odd-list lay-rate lay-2">--</div>
                      <div class="odd-list back-rate back-3">
                        {{ x?.marketBook?.runners[1]?.availableToBack?.price }}
                      </div>
                      <div class="odd-list lay-rate lay-3">
                        {{ x?.marketBook?.runners[1]?.availableToLay?.price }}
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </ng-container>
          </ng-container>

          <!-- Soccer -->
          <ng-container *ngIf="var_football">
            <ng-container *ngIf="soccerData?.length">
              <div class="game-list-card">
                <!-- <div class="game-list-header">
              <img src="assets/icons/Soccer.png">Soccer ({{soccerData?.length}})
            </div> -->
                <mat-card *ngFor="let x of soccerData" class="match-event-list m-1"
                  (click)="matchDetail(x.eventId,x.eventTypeId)">
                  <div class="match-name" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                    <div class="col-8">
                      <!-- <div class="odd-content "> -->
                      <div class="odd-title" style="margin-bottom: 5px; word-spacing: 3px">
                        <span style="
                            line-height: 18px;
                            font-size: 14px;
                            font-weight: 600;
                            display: block;
                          ">{{ x?.eventName }}</span>
                      </div>
                      <div class="date_time">
                        <span>{{
                          moment(x?.openDate)
                          .utcOffset("+05:30")
                          .format("MMM D YYYY")
                          }}
                          {{
                          moment(x?.openDate)
                          .utcOffset("+05:30")
                          .format("h:mm A")
                          }}
                          (IST)</span>
                      </div>
                    </div>
                    <div class="col-3" style="text-align: end">
                      <span class="green_point"><img src="../../assets/icons/Green-Circle.png" width="10"
                          height="10" /></span>
                      <span class="tv_icon"><i class="fa fa-tv"></i></span>
                      <span class="bw_word"><b><i>BM</i></b></span>
                    </div>
                  </div>
                  <div class="row" style="flex-wrap: nowrap !important">
                    <div class="col-sm-4 text-center">
                      <span style="margin-left: 31px; font-weight: 600">1</span>
                    </div>
                    <div class="col-sm-4 text-center">
                      <span style="font-weight: 600">x</span>
                    </div>
                    <div class="col-sm-4 text-center">
                      <span style="margin-right: 31px; font-weight: 600">2</span>
                    </div>
                  </div>
                  <div class="event-odds" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                    <div class="match-rate">
                      <div class="odd-list back-rate back-1">
                        {{ x?.marketBook?.runners[0]?.availableToBack?.price }}
                      </div>
                      <div class="odd-list lay-rate lay-1">
                        {{ x?.marketBook?.runners[0]?.availableToLay?.price }}
                      </div>
                      <div class="odd-list back-rate back-2">--</div>
                      <div class="odd-list lay-rate lay-2">--</div>
                      <div class="odd-list back-rate back-3">
                        {{ x?.marketBook?.runners[1]?.availableToBack?.price }}
                      </div>
                      <div class="odd-list lay-rate lay-3">
                        {{ x?.marketBook?.runners[1]?.availableToLay?.price }}
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </ng-container>
          </ng-container>

          <!-- Tennis -->
          <ng-container *ngIf="var_tennis">
            <ng-container *ngIf="tennisData?.length">
              <div class="game-list-card">
                <!-- <div class="game-list-header">
              <img src="assets/icons/Tennis.png">Tennis ({{tennisData?.length}})
            </div> -->
                <mat-card *ngFor="let x of tennisData" class="match-event-list m-1"
                  (click)="matchDetail(x.eventId,x.eventTypeId)">
                  <div class="match-name" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                    <div class="col-8">
                      <!-- <div class="odd-content "> -->
                      <div class="odd-title" style="margin-bottom: 5px; word-spacing: 3px">
                        <span style="
                            line-height: 18px;
                            font-size: 14px;
                            font-weight: 600;
                            display: block;
                          ">{{ x?.eventName }}</span>
                      </div>
                      <div class="date_time">
                        <span>{{
                          moment(x?.openDate)
                          .utcOffset("+05:30")
                          .format("MMM D YYYY")
                          }}
                          {{
                          moment(x?.openDate)
                          .utcOffset("+05:30")
                          .format("h:mm A")
                          }}
                          (IST)</span>
                      </div>
                    </div>
                    <div class="col-3" style="text-align: end">
                      <span class="green_point"><img src="../../assets/icons/Green-Circle.png" width="10"
                          height="10" /></span>
                      <span class="tv_icon"><i class="fa fa-tv"></i></span>
                      <span class="bw_word"><b><i>BM</i></b></span>
                    </div>
                  </div>
                  <div class="row" style="flex-wrap: nowrap !important">
                    <div class="col-sm-4 text-center">
                      <span style="margin-left: 31px; font-weight: 600">1</span>
                    </div>
                    <div class="col-sm-4 text-center">
                      <span style="font-weight: 600">x</span>
                    </div>
                    <div class="col-sm-4 text-center">
                      <span style="margin-right: 31px; font-weight: 600">2</span>
                    </div>
                  </div>
                  <div class="event-odds" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                    <div class="match-rate">
                      <div class="odd-list back-rate back-1">
                        {{ x?.marketBook?.runners[0]?.availableToBack?.price }}
                      </div>
                      <div class="odd-list lay-rate lay-1">
                        {{ x?.marketBook?.runners[0]?.availableToLay?.price }}
                      </div>
                      <div class="odd-list back-rate back-2">--</div>
                      <div class="odd-list lay-rate lay-2">--</div>
                      <div class="odd-list back-rate back-3">
                        {{ x?.marketBook?.runners[1]?.availableToBack?.price }}
                      </div>
                      <div class="odd-list lay-rate lay-3">
                        {{ x?.marketBook?.runners[1]?.availableToLay?.price }}
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="
              (soccerData?.length == 0 && var_football) ||
              (tennisData?.length == 0 && var_tennis) ||
              (cricketData?.length == 0 &&
                virtualCricketData?.length == 0 &&
                var_cricket)
            ">
            <div *ngIf="casinoStatus" style="background-color: #cccccc" class="text-dark text-center p-2">
              No real-time records found
            </div>
          </ng-container>
        </div>

        <!-- casinoFooter -->
        <ng-container *ngIf="!deviceInfo">
          <!-- casinoFilter start -->
          <div class="mobile-top-bar" *ngIf="!casinoStatus">
            <div class="bottom-menu">
              <ng-container *ngIf="casino_menu">
                <mat-list *ngIf="our_casino_tab">
                  <mat-list-item (click)="casino_games('OUR CASINO');"
                    [ngClass]="{'active': casino_type === 'OUR CASINO'}"> <span>OUR CASINO</span></mat-list-item>
                </mat-list>
                <!-- live_casino -->
                <mat-list *ngIf="live_casino_tab">
                  <ng-container *ngIf="casino_menu">
                    <span class="dash_line" style="
                      margin-top: 3px;
                      color: #ffff;
                      font-size: 1.5rem;
                      font-weight: 100;
                    ">|</span>
                    <mat-list-item (click)="casino_games('LIVECASINO')"
                      [ngClass]="{ active: casino_type === 'LIVECASINO' }">
                      <span>LIVE CASINO</span></mat-list-item><span class="dash_line" style="
                      margin-top: 3px;
                      color: #ffff;
                      font-size: 1.5rem;
                      font-weight: 100;
                    ">|</span>
                    <mat-list-item (click)="casino_games('TABLEGAME')"
                      [ngClass]="{ active: casino_type === 'TABLEGAME' }">
                      <span>TABLE GAMES</span></mat-list-item>
                    <span class="dash_line" style="
                      margin-top: 3px;
                      color: #ffff;
                      font-size: 1.5rem;
                      font-weight: 100;
                    ">|</span>
                    <mat-list-item (click)="casino_games('SHOOTING')"
                      [ngClass]="{ active: casino_type === 'SHOOTING' }">
                      <span>SHOOTING GAMES</span></mat-list-item><span class="dash_line" style="
                      margin-top: 3px;
                      color: #ffff;
                      font-size: 1.5rem;
                      font-weight: 100;
                    ">|</span>
                    <mat-list-item (click)="casino_games('INSTANTWIN')"
                      [ngClass]="{ active: casino_type === 'INSTANTWIN' }">
                      <span>INSTANT WIN </span>
                    </mat-list-item>
                    <span class="dash_line" style="
                      margin-top: 3px;
                      color: #ffff;
                      font-size: 1.5rem;
                      font-weight: 100;
                    ">|</span>
                    <mat-list-item (click)="casino_games('SCRATCHCARD')"
                      [ngClass]="{ active: casino_type === 'SCRATCHCARD' }">
                      <span>SCRATCH CARD </span>
                    </mat-list-item>
                    <span class="dash_line" style="
                      margin-top: 3px;
                      color: #ffff;
                      font-size: 1.5rem;
                      font-weight: 100;
                    ">|</span>
                    <mat-list-item (click)="casino_games('VIRTUAL_SPORTS')"
                      [ngClass]="{ active: casino_type === 'VIRTUAL_SPORTS' }">
                      <span>VIRTUAL SPORTS </span>
                    </mat-list-item>

                  </ng-container>
                </mat-list>
                <!-- slots -->
                <mat-list *ngIf="slot_game_tab">
                  <mat-list-item class="active" (click)="casino_games('SLOT')"
                    [ngClass]="{ active: casino_type === 'SLOT' }">
                    <span>SLOTS</span></mat-list-item>
                </mat-list>
              </ng-container>
            </div>
          </div>
          <!-- casinoFilter end -->

          <div *ngIf="casinoFooter" class="multi-card">
            <div class="scroll-card" style="padding: 0 14px 14px">
              <!-- type_filter -->
              <div *ngIf="casino_type != 'OUR CASINO' && !casinoStatus"
                class="itemsContain casioncustom slotsmallgames">
                <!-- live_casino -->
                <!-- <ng-container *ngIf="casino_type === 'LIVECASINO'">
                  <div class="card" (click)="openCasino('BTL-betonteenpatti', '')">
                    <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/livecasino/livecasinogaming.jpg"
                      class="card-img-top" alt="..." />
                    <div class="button"><a>Pragmatic Play</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-cricketwar', '')">
                    <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/livecasino/euzgi_casino.png"
                      class="card-img-top" alt="..." />
                    <div class="button"><a>Euzgi Casino</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EVO-blackjack', '')">
                    <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/livecasino/evolution_casino.png"
                      class="card-img-top" alt="..." />
                    <div class="button"><a>Evolution Casino</a></div>
                  </div>
                </ng-container> -->
                <!-- mobile_slot -->
                <div class="d-flex flex-row card-group">
                  <ng-container *ngIf="casino_data?.length > 0">
                    <div class="card" *ngFor="let x of casino_data" style="padding: 0px 5px 0px 5px" style="width:48%;">
                      <!-- <ng-container *ngIf="x.id!='RED-10001nights'"> -->
                      <div *ngIf="x.images" class="card-img" (click)="openCasino(x.id, '')">
                        <img [src]="x?.images[1]?.url" class="card-img-top" alt="..." />
                        <!-- <div class="button"><a>{{x.name}}</a></div> -->
                      </div>
                      <!-- </ng-container> -->
                    </div>
                  </ng-container>
                </div>
              </div>







              <!-- mobile_ourCasino -->

              <ng-container *ngIf="live_casino_menu">
                <h3 class="titlesecnew mb-0">LIVE CASINO</h3>
                <div class="mobnewgames">
                  <div class="new-games-container">
                    <div class="row mx-0">
                      <!-- openCasino('WOO-wolfrush','') -->
                      <div *ngIf="live_casino_menu" (click)="openCasino('','')" tabindex="0"
                        class="col-12 px-1 position-relative">
                        <img src="../../assets/images/newgames/popular-games-banner.webp" class="img-fluid">
                      </div>

                    </div>
                  </div>
                </div>
              </ng-container>
              <div *ngIf="casino_type==='OUR CASINO' && casino_menu" class="itemsContain casioncustom">

                <h3 *ngIf="casinoStatus" class="titlesecnew">OUR CASINO</h3>
                <div class="mobnewgames">
                  <div class="new-games-container">
                    <div class="row mx-0">
                      <!-- openCasino('','67722-2') -->
                      <div (click)="openCasino('SPB-aviator','')" tabindex="0" class="col-6 px-1 position-relative">
                        <img src="../../assets/images/newgames/aviator-banner.webp" class="img-fluid">
                      </div>
                      <!-- openCasino('','67630') -->
                      <div (click)="openCasino('YGG-theroyalfamily','')" tabindex="0"
                        class="col-6 px-1 position-relative">
                        <img src="../../assets/images/newgames/liveteenpatti-banner.webp" class="img-fluid">
                      </div>
                      <!-- openCasino('','') -->
                      <!-- openCasino('WOO-wolfrush','') -->
                      <!-- <div *ngIf="live_casino_menu" (click)="openCasino('','')" tabindex="0"
                        class="col-12 px-1 position-relative">
                        <img src="../../assets/images/newgames/popular-games-banner.webp" class="img-fluid">
                      </div> -->

                    </div>
                  </div>
                </div>
                <!-- hide_Qtech -->
                <!-- <ng-container *ngIf="false"> -->
                <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">
                  <!-- <div class="card" (click)="openCasino('', '67720')"> -->
                  <div class="card" (click)="openCasino('EZU-cricketwar','45100')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/superover.jpg" class="card-img-top"
                      alt="..." />
                    <!-- <a>
                      <div class="new-launch-casino">New Launch</div>
                    </a>
                    <div class="button"><a>Super Over</a></div> -->
                  </div>
                  <div class="card" (click)="openCasino('TRB-ballball','')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/ballbyball.jpg" class="card-img-top" />
                    <div class="button"><a>7 Up down</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-teenpatti','227101')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teen32.jpg" class="card-img-top" />
                    <div class="button"><a>Auto Roulette</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-teenpatti','227101')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teen3.jpg" class="card-img-top" />
                    <div class="button"><a>Teen Patti</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-roulette','541000')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/roulette1.jpg" class="card-img-top" />
                    <div class="button"><a>Cricket War</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-threecardpoker','227100')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/3cardj.jpg" class="card-img-top" />
                    <div class="button"><a>3 Cards Judgement</a></div>
                  </div>
                  <div class="card" (click)="openCasino('HOG-lobby','')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/lottcard.jpg" class="card-img-top" />
                    <div class="button"><a>Lottery</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-threecardpoker','227100')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/poker.jpg" class="card-img-top" />
                    <div class="button"><a>Book Cricket</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-nocommissionbaccarat', '227103')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teen.jpg" class="card-img-top" />
                    <div class="button"><a>One Day Teenpatti</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-baccarat','100')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/baccarat2.jpg" class="card-img-top" />
                    <div class="button"><a>Akbar Romeo Walter</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-teenpatti','227100')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/patti2.jpg" class="card-img-top" />
                    <div class="button"><a>2 Cards Teenpatti</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-teenpatti','227101')">
                    <img src="../../assets/images/teen20b.jpg" class="card-img-top" />
                    <div class="button"><a>Dragon Tiger One day</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-dragontiger','150')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/dt6.jpg" class="card-img-top" />
                    <div class="button"><a>Dragon Tiger Lion</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-teenpatti','227100')">
                    <img src="../../assets/images/kbc.jpg" class="card-img-top" />
                    <div class="button"><a>2 Card Teen Patti One Day</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-casinoholdem','507000')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/dtl20.jpg" class="card-img-top" />
                    <div class="button"><a>Center Card</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-andarbahar','228000')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/abj.jpg" class="card-img-top" />
                    <div class="button"><a>Foot Ball Studio</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-lucky7','228001')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/lucky7eu.jpg" class="card-img-top" />
                    <div class="button"><a>Movie Casino</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-cricketwar','45100')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/cricketv3.jpg" class="card-img-top" />
                    <div class="button"><a>Side Bet City</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-cricketwar','45100')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/cmatch20.jpg" class="card-img-top" />
                    <div class="button"><a>2 card 1 day</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-teenpatti','227101')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teen6.jpg" class="card-img-top" />
                    <div class="button"><a>Center Card 1 Day</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-lucky7','228001')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/lucky7eu2.jpg" class="card-img-top" />
                    <div class="button"><a>Muflis 1 Day</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-super6baccarat','130')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teensin.jpg" class="card-img-top" />
                    <div class="button"><a>Super Over 1 day</a></div>
                  </div>
                  <div class="card" (click)="openCasino('CQC-firequeen2','')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/queen.jpg" class="card-img-top" />
                    <div class="button"><a>High Card</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-dragontiger','150')">
                    <img src="../../assets/images/dragon20.jpg" class="card-img-top" />
                    <div class="button"><a>Queen Race</a></div>
                  </div>
                  <div class="card" (click)="openCasino('BTG-theracemegaways','')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/race20.jpg" class="card-img-top" />
                    <div class="button"><a>Royal Gaming Lobby</a></div>
                  </div>

                  <!-- extra -->
                  <div class="card" (click)="openCasino('EVO-dreamcatcher','')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/notenum.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>RACE 20</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-roulette','541000')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/roulette.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>Dream Catcher</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-sicbo','224000')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/sicbo.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>ROULETTE</a></div>
                  </div>
                </div>
                <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">
                  <div class="card" (click)="openCasino('EZU-dragontiger','150')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/dt202.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>Sicbo</a></div>
                  </div>

                  <div class="card" (click)="openCasino('SAG-teenpatti2020','227101')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teen20.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>DRAGON TIGER</a></div>
                  </div>

                  <div class="card" (click)="openCasino('EZU-baccarat','100')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/baccarat.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>TEENPATTI T20</a></div>
                  </div>
                  <!-- (click)="openCasinoDetail('EZU-baccarat','100')" -->
                  <div class="card" (click)="openCasino('EZU-lucky7','228001')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/lucky7.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>Baccarat</a></div>
                  </div>
                </div>
                <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">
                  <div class="card" (click)="openCasino('EZU-nocommissionbaccarat', '227103')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teen1.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>lucky7</a></div>
                  </div>
                  <!-- 
                  <div class="card" (click)="openCasino('TRB-hilo','')">
                    <img src="../../assets/images/game-icon/rg_poker_vr.webp" class="card-img-top" alt="..." />
                    <div class="button"><a>HIGH LOW</a></div>
                  </div> -->

                  <div class="card" (click)="openCasino('EZU-teenpatti','227101')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teen9.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>POKER</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-32cards','228002')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/card32.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>TEENPATTI TEST</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-32cards','228002')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/card32eu.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>32 CARD CASINO</a></div>
                  </div>
                </div>
                <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">


                  <div class="card" (click)="openCasino('KGL-deeprush','')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/worli.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>WORLI MATKA</a></div>
                  </div>

                  <div class="card" (click)="openCasino('BTV-instantlucky7','')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/worli2.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>Teenpatti Open</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-teenpatti','227101')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teen8.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>POKER 20-20</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-threecardpoker','227100')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/poker20.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>29 CARD BACCARAT</a></div>
                  </div>
                </div>
                <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">

                  <div class="card" (click)="openCasino('EZU-super6baccarat','130')">
                    <img src="../../assets/images/teen120.jpg" class="card-img-top" alt="..." />
                    <div class="button"><a>MUFLIS TEENPATTI</a></div>
                  </div>

                  <div class="card" (click)="openCasino('EZU-teenpatti','227101')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/teenmuf.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>RACE TO 17</a></div>
                  </div>
                  <div class="card" (click)="openCasino('PPL-nocommspeedbaccarat2','171')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/race17.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>TRIO</a></div>
                  </div>
                  <div class="card" (click)="openCasino('WOO-tricardpoker','')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/trio.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>THE TRAP</a></div>
                  </div>

                </div>
                <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">

                  <div class="card" (click)="openCasino('NE-crabtrap','')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/trap.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>Andar Bahar</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-andarbahar','228000')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/ab20.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>SIX PLAYER POKER</a></div>
                  </div>
                  <div class="card" (click)="openCasino('BTV-6poker','227200')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/poker6.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>CASINO METER</a></div>
                  </div>
                  <div class="card" (click)="openCasino('EZU-casinoholdem','507000')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/cmeter.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>CASINO WAR</a></div>
                  </div>
                </div>
                <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">


                  <div class="card" (click)="openCasino('EZU-casinoholdem','507000')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/war.jpg" class="card-img-top" alt="..." />
                    <div class="button"><a>Amar Akbar Anthony</a></div>
                  </div>

                  <div class="card" (click)="openCasino('NE-bollywoodstory','')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/aaa.jpg" class="card-img-top" alt="..." />
                    <div class="button"><a>Bollywood Casino</a></div>
                  </div>



                  <div class="card" (click)="openCasino('EZU-casinoholdem','507000')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/btable.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>Bollywood Casino</a></div>
                  </div>
                  <div class="card" (click)="openCasino('TPG-andarbahar','')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/ab3.jpg" class="card-img-top" alt="..." />
                    <div class="button"><a>Bollywood Casino</a></div>
                  </div>
                  <div class="card" (click)="openCasino('NE-bollywoodstory','')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/aaa2.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>Bollywood Casino</a></div>
                  </div>
                  <div class="card" (click)="openCasino('PNG-spacerace','')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/race2.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>Bollywood Casino</a></div>
                  </div>
                  <div class="card" (click)="openCasino('YGG-goldenchiproulette','')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/dum10.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>Bollywood Casino</a></div>
                  </div>
                  <div class="card" (click)="openCasino('WAZ-threecards','')">
                    <img src="https://nd.sprintstaticdata.com/casino-icons/lc/cmeter1.jpg" class="card-img-top"
                      alt="..." />
                    <div class="button"><a>Bollywood Casino</a></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <!-- paisaexch -->
    <ng-container *ngIf="page_type === 'paisaexch'">
      <app-p-dashboard *ngIf="casinoStatus"></app-p-dashboard>

      <!-- b_casinoFooter -->

      <ng-container *ngIf="!deviceInfo">
        <!-- casinoFilter start -->
        <div class="mobile-top-bar" style="margin-top: 4rem" *ngIf="!casinoStatus">
          <div class="bottom-menu">
            <mat-list>
              <mat-list-item *ngIf="casino_menu" (click)="casino_games('OUR CASINO')"
                [ngClass]="{ active: casino_type === 'OUR CASINO' }">
                <span>OUR CASINO</span></mat-list-item>
              <ng-container *ngIf="live_casino_menu">
                <span class="dash_line" style="
                    margin-top: 3px;
                    color: #ffff;
                    font-size: 1.5rem;
                    font-weight: 100;
                  ">|</span>
                <mat-list-item (click)="casino_games('LIVECASINO')"
                  [ngClass]="{ active: casino_type === 'LIVECASINO' }">
                  <span>LIVE CASINO</span></mat-list-item><span class="dash_line" style="
                    margin-top: 3px;
                    color: #ffff;
                    font-size: 1.5rem;
                    font-weight: 100;
                  ">|</span>
                <mat-list-item (click)="casino_games('SLOT')" [ngClass]="{ active: casino_type === 'SLOT' }">
                  <span>SLOTS</span></mat-list-item><span class="dash_line" style="
                    margin-top: 3px;
                    color: #ffff;
                    font-size: 1.5rem;
                    font-weight: 100;
                  ">|</span>
                <mat-list-item (click)="casino_games('TABLEGAME')" [ngClass]="{ active: casino_type === 'TABLEGAME' }">
                  <span>TABLE GAMES</span></mat-list-item>
                <span class="dash_line" style="
                    margin-top: 3px;
                    color: #ffff;
                    font-size: 1.5rem;
                    font-weight: 100;
                  ">|</span>
                <mat-list-item (click)="casino_games('SHOOTING')" [ngClass]="{ active: casino_type === 'SHOOTING' }">
                  <span>SHOOTING GAMES</span></mat-list-item><span class="dash_line" style="
                    margin-top: 3px;
                    color: #ffff;
                    font-size: 1.5rem;
                    font-weight: 100;
                  ">|</span>
                <mat-list-item (click)="casino_games('INSTANTWIN')"
                  [ngClass]="{ active: casino_type === 'INSTANTWIN' }">
                  <span>INSTANT WIN </span>
                </mat-list-item>
                <span class="dash_line" style="
                    margin-top: 3px;
                    color: #ffff;
                    font-size: 1.5rem;
                    font-weight: 100;
                  ">|</span>
                <mat-list-item (click)="casino_games('SCRATCHCARD')"
                  [ngClass]="{ active: casino_type === 'SCRATCHCARD' }">
                  <span>SCRATCH CARD </span>
                </mat-list-item>
                <span class="dash_line" style="
                    margin-top: 3px;
                    color: #ffff;
                    font-size: 1.5rem;
                    font-weight: 100;
                  ">|</span>
                <mat-list-item (click)="casino_games('VIRTUAL_SPORTS')"
                  [ngClass]="{ active: casino_type === 'VIRTUAL_SPORTS' }">
                  <span>VIRTUAL SPORTS </span>
                </mat-list-item>
                <!-- <span class="dash_line" style=" margin-top: 3px; color: #ffff;
                font-size: 1.5rem;font-weight: 100;">|</span>
                <mat-list-item (click)="casino_games('AURA_CASINO');" [ngClass]="{'active': casino_type === 'AURA_CASINO'}"> <span>AURA CASINO </span> </mat-list-item> -->
              </ng-container>
            </mat-list>
          </div>
        </div>
        <!-- casinoFilter end -->

        <div *ngIf="casinoFooter" class="multi-card">
          <div class="scroll-card" style="padding: 0 14px 14px">
            <!-- type_filter -->
            <div *ngIf="casino_type != 'OUR CASINO' && !casinoStatus" class="itemsContain casioncustom slotsmallgames">
              <!-- live_casino -->
              <ng-container *ngIf="casino_type === 'LIVECASINO'">
                <div class="card" (click)="openCasino('BTL-betonteenpatti', '')">
                  <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/livecasino/livecasinogaming.jpg"
                    class="card-img-top" alt="..." />
                  <div class="button"><a>Pragmatic Play</a></div>
                </div>
                <div class="card" (click)="openCasino('EZU-cricketwar', '')">
                  <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/livecasino/euzgi_casino.png"
                    class="card-img-top" alt="..." />
                  <div class="button"><a>Euzgi Casino</a></div>
                </div>
                <div class="card" (click)="openCasino('EVO-blackjack', '')">
                  <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/livecasino/evolution_casino.png"
                    class="card-img-top" alt="..." />
                  <div class="button"><a>Evolution Casino</a></div>
                </div>
              </ng-container>

              <div class="d-flex card-group">
                <ng-container *ngIf="casino_data?.length > 0">
                  <div class="card" *ngFor="let x of casino_data" style="padding: 0px 5px 0px 5px">
                    <div *ngIf="x.images" class="card-img" (click)="openCasino(x.id, '')">
                      <img [src]="x.images[2].url" class="card-img-top" alt="..." />
                      <!-- <div class="button"><a>{{x.name}}</a></div> -->
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <!-- ourCasino -->
            <div *ngIf="
                casino_type === 'OUR CASINO' && casino_menu && !casinoStatus
              " class="itemsContain casioncustom">
              <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">
                <div class="card" (click)="openCasino('', '67720')">
                  <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/superover.jpg"
                    class="card-img-top" alt="..." />
                  <a>
                    <div class="new-launch-casino">New Launch</div>
                  </a>
                  <div class="button"><a>Super Over</a></div>
                </div>

                <div class="card" (click)="openCasino('', '90100')">
                  <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/race20.png"
                    class="card-img-top" alt="..." />
                  <div class="button"><a>RACE 20</a></div>
                </div>
                <div class="card" (click)="openCasino('', '98567')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/Dream%20Catcher.png" class="card-img-top"
                    alt="..." />
                  <div class="button"><a>Dream Catcher</a></div>
                </div>
                <div class="card" (click)="openCasino('', '98788')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/Roulette.png" class="card-img-top" alt="..." />
                  <div class="button"><a>ROULETTE</a></div>
                </div>
              </div>
              <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">
                <div class="card" (click)="openCasino('', '98566')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/Sicbo.png" class="card-img-top" alt="..." />
                  <div class="button"><a>Sicbo</a></div>
                </div>

                <div class="card" (click)="openCasino('', '98790')">
                  <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/dt202.jpg"
                    class="card-img-top" alt="..." />
                  <div class="button"><a>DRAGON TIGER</a></div>
                </div>

                <div class="card" (click)="openCasino('', '56768')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/Teenpatti%20T20.png" class="card-img-top"
                    alt="..." />
                  <div class="button"><a>TEENPATTI T20</a></div>
                </div>
                <!-- (click)="openCasino('EZU-baccarat','100')" -->
                <div class="card" (click)="openCasino('', '92038')">
                  <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/baccarat.png"
                    class="card-img-top" alt="..." />
                  <div class="button"><a>Baccarat</a></div>
                </div>
              </div>
              <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">
                <div class="card" (click)="openCasino('', '98789')">
                  <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/lucky7eu.jpg"
                    class="card-img-top" alt="..." />
                  <div class="button"><a>lucky7</a></div>
                </div>

                <div class="card" (click)="openCasino('', '56968')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/Hi%20Low.png" class="card-img-top" alt="..." />
                  <div class="button"><a>HIGH LOW</a></div>
                </div>

                <div class="card" (click)="openCasino('', '67564')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/Poker.png" class="card-img-top" alt="..." />
                  <div class="button"><a>POKER</a></div>
                </div>
                <div class="card" (click)="openCasino('', '67630')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/Teenpatti%20Test.png" class="card-img-top"
                    alt="..." />
                  <div class="button"><a>TEENPATTI TEST</a></div>
                </div>
              </div>
              <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">
                <div class="card" (click)="openCasino('', '56967')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/32%20cards%20casino.png" class="card-img-top"
                    alt="..." />
                  <div class="button"><a>32 CARD CASINO</a></div>
                </div>

                <div class="card" (click)="openCasino('', '92037')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/Worli%20Matka.png" class="card-img-top"
                    alt="..." />
                  <div class="button"><a>WORLI MATKA</a></div>
                </div>

                <div class="card" (click)="openCasino('', '67640 ')">
                  <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/teenpatti.jpg"
                    class="card-img-top" alt="..." />
                  <div class="button"><a>Teenpatti Open</a></div>
                </div>
                <div class="card" (click)="openCasino('', '67567')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/Poker%2020-20.png" class="card-img-top"
                    alt="..." />
                  <div class="button"><a>POKER 20-20</a></div>
                </div>
              </div>
              <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">
                <div class="card" (click)="openCasino('', '67690')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/29%20Card%20Baccarat.png" class="card-img-top"
                    alt="..." />
                  <div class="button"><a>29 CARD BACCARAT</a></div>
                </div>

                <div class="card" (click)="openCasino('', '67600')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/Muflis%20Teenpatti.png" class="card-img-top"
                    alt="..." />
                  <div class="button"><a>MUFLIS TEENPATTI</a></div>
                </div>

                <div class="card" (click)="openCasino('', '67710')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/Race%20to%2017.png" class="card-img-top"
                    alt="..." />
                  <div class="button"><a>RACE TO 17</a></div>
                </div>
                <div class="card" (click)="openCasino('', '67610')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/Trio.png" class="card-img-top" alt="..." />
                  <div class="button"><a>TRIO</a></div>
                </div>
              </div>
              <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">
                <div class="card" (click)="openCasino('', '67680')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/The%20Trap.png" class="card-img-top"
                    alt="..." />
                  <div class="button"><a>THE TRAP</a></div>
                </div>

                <div class="card" (click)="openCasino('', '87564')">
                  <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/andar-bahar.jpg"
                    class="card-img-top" alt="..." />
                  <div class="button"><a>Andar Bahar</a></div>
                </div>
                <div class="card" (click)="openCasino('', '67565')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/Six%20player%20poker.png" class="card-img-top"
                    alt="..." />
                  <div class="button"><a>SIX PLAYER POKER</a></div>
                </div>
                <div class="card" (click)="openCasino('', '67575')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/Casino%20Meter.png" class="card-img-top"
                    alt="..." />
                  <div class="button"><a>CASINO METER</a></div>
                </div>
              </div>
              <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">
                <div class="card" (click)="openCasino('', '67580')">
                  <img src="https://m2.fawk.app/assets/images/Games/new/Casino%20War.png" class="card-img-top"
                    alt="..." />
                  <div class="button"><a>CASINO WAR</a></div>
                </div>

                <div class="card" (click)="openCasino('', '98791')">
                  <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/aaa.jpg"
                    class="card-img-top" alt="..." />
                  <div class="button"><a>Amar Akbar Anthony</a></div>
                </div>

                <div class="card" (click)="openCasino('', '67570')">
                  <img
                    src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/bollywood-casino.jpg"
                    class="card-img-top" alt="..." />
                  <div class="button"><a>Bollywood Casino</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <app-p-footer></app-p-footer>
    </ng-container>

    <!-- deshboard_footer -->
    <!-- <app-desk-footer *ngIf="deviceInfo"></app-desk-footer> -->
    <app-abh-footer></app-abh-footer>
  </mat-sidenav-content>
</mat-sidenav-container>



<!-- bet_Honk -->
<ng-container *ngIf="page_type === 'betHonk'">
  <app-b-header></app-b-header>
  <app-b-dashboard *ngIf="casinoStatus"></app-b-dashboard>
  <!-- b_casinoFooter -->
  <ng-container *ngIf="!deviceInfo">
    <!-- casinoFilter start -->
    <div class="mobile-top-bar" style="margin-top: 8rem" *ngIf="!casinoStatus">
      <div class="bottom-menu">
        <mat-list>
          <mat-list-item *ngIf="casino_menu" (click)="casino_games('OUR CASINO')"
            [ngClass]="{ active: casino_type === 'OUR CASINO' }">
            <span>OUR CASINO</span></mat-list-item>
          <ng-container *ngIf="live_casino_menu">
            <span class="dash_line" style="
                margin-top: 3px;
                color: #ffff;
                font-size: 1.5rem;
                font-weight: 100;
              ">|</span>
            <mat-list-item (click)="casino_games('LIVECASINO')" [ngClass]="{ active: casino_type === 'LIVECASINO' }">
              <span>LIVE CASINO</span></mat-list-item><span class="dash_line" style="
                margin-top: 3px;
                color: #ffff;
                font-size: 1.5rem;
                font-weight: 100;
              ">|</span>
            <mat-list-item (click)="casino_games('SLOT')" [ngClass]="{ active: casino_type === 'SLOT' }">
              <span>SLOTS</span></mat-list-item><span class="dash_line" style="
                margin-top: 3px;
                color: #ffff;
                font-size: 1.5rem;
                font-weight: 100;
              ">|</span>
            <mat-list-item (click)="casino_games('TABLEGAME')" [ngClass]="{ active: casino_type === 'TABLEGAME' }">
              <span>TABLE GAMES</span></mat-list-item>
            <span class="dash_line" style="
                margin-top: 3px;
                color: #ffff;
                font-size: 1.5rem;
                font-weight: 100;
              ">|</span>
            <mat-list-item (click)="casino_games('SHOOTING')" [ngClass]="{ active: casino_type === 'SHOOTING' }">
              <span>SHOOTING GAMES</span></mat-list-item><span class="dash_line" style="
                margin-top: 3px;
                color: #ffff;
                font-size: 1.5rem;
                font-weight: 100;
              ">|</span>
            <mat-list-item (click)="casino_games('INSTANTWIN')" [ngClass]="{ active: casino_type === 'INSTANTWIN' }">
              <span>INSTANT WIN </span>
            </mat-list-item>
            <span class="dash_line" style="
                margin-top: 3px;
                color: #ffff;
                font-size: 1.5rem;
                font-weight: 100;
              ">|</span>
            <mat-list-item (click)="casino_games('SCRATCHCARD')" [ngClass]="{ active: casino_type === 'SCRATCHCARD' }">
              <span>SCRATCH CARD </span>
            </mat-list-item>
            <span class="dash_line" style="
                margin-top: 3px;
                color: #ffff;
                font-size: 1.5rem;
                font-weight: 100;
              ">|</span>
            <mat-list-item (click)="casino_games('VIRTUAL_SPORTS')"
              [ngClass]="{ active: casino_type === 'VIRTUAL_SPORTS' }">
              <span>VIRTUAL SPORTS </span>
            </mat-list-item>
            <!-- <span class="dash_line" style=" margin-top: 3px; color: #ffff;
              font-size: 1.5rem;font-weight: 100;">|</span>
              <mat-list-item (click)="casino_games('AURA_CASINO');" [ngClass]="{'active': casino_type === 'AURA_CASINO'}"> <span>AURA CASINO </span> </mat-list-item> -->
          </ng-container>
        </mat-list>
      </div>
    </div>
    <!-- casinoFilter end -->

    <div *ngIf="casinoFooter" class="multi-card">
      <div class="scroll-card" style="padding: 0 14px 14px">
        <!-- type_filter -->
        <div *ngIf="casino_type != 'OUR CASINO' && !casinoStatus" class="itemsContain casioncustom slotsmallgames">
          <!-- live_casino -->
          <ng-container *ngIf="casino_type === 'LIVECASINO'">
            <div class="card" (click)="openCasino('BTL-betonteenpatti', '')">
              <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/livecasino/livecasinogaming.jpg"
                class="card-img-top" alt="..." />
              <div class="button"><a>Pragmatic Play</a></div>
            </div>
            <div class="card" (click)="openCasino('EZU-cricketwar', '')">
              <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/livecasino/euzgi_casino.png"
                class="card-img-top" alt="..." />
              <div class="button"><a>Euzgi Casino</a></div>
            </div>
            <div class="card" (click)="openCasino('EVO-blackjack', '')">
              <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/livecasino/evolution_casino.png"
                class="card-img-top" alt="..." />
              <div class="button"><a>Evolution Casino</a></div>
            </div>
          </ng-container>

          <div class="d-flex card-group">
            <ng-container *ngIf="casino_data?.length > 0">
              <div class="card" *ngFor="let x of casino_data" style="padding: 0px 5px 0px 5px">
                <div *ngIf="x.images" class="card-img" (click)="openCasino(x.id, '')">
                  <img [src]="x.images[2].url" class="card-img-top" alt="..." />
                  <!-- <div class="button"><a>{{x.name}}</a></div> -->
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <!-- ourCasino -->
        <div *ngIf="casino_type === 'OUR CASINO' && casino_menu && !casinoStatus" class="itemsContain casioncustom">
          <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">
            <div class="card" (click)="openCasino('', '67720')">
              <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/superover.jpg"
                class="card-img-top" alt="..." />
              <a>
                <div class="new-launch-casino">New Launch</div>
              </a>
              <div class="button"><a>Super Over</a></div>
            </div>

            <div class="card" (click)="openCasino('', '90100')">
              <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/race20.png"
                class="card-img-top" alt="..." />
              <div class="button"><a>RACE 20</a></div>
            </div>
            <div class="card" (click)="openCasino('', '98567')">
              <img src="https://m2.fawk.app/assets/images/Games/new/Dream%20Catcher.png" class="card-img-top"
                alt="..." />
              <div class="button"><a>Dream Catcher</a></div>
            </div>
            <div class="card" (click)="openCasino('', '98788')">
              <img src="https://m2.fawk.app/assets/images/Games/new/Roulette.png" class="card-img-top" alt="..." />
              <div class="button"><a>ROULETTE</a></div>
            </div>
          </div>
          <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">
            <div class="card" (click)="openCasino('', '98566')">
              <img src="https://m2.fawk.app/assets/images/Games/new/Sicbo.png" class="card-img-top" alt="..." />
              <div class="button"><a>Sicbo</a></div>
            </div>

            <div class="card" (click)="openCasino('', '98790')">
              <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/dt202.jpg"
                class="card-img-top" alt="..." />
              <div class="button"><a>DRAGON TIGER</a></div>
            </div>

            <div class="card" (click)="openCasino('', '56768')">
              <img src="https://m2.fawk.app/assets/images/Games/new/Teenpatti%20T20.png" class="card-img-top"
                alt="..." />
              <div class="button"><a>TEENPATTI T20</a></div>
            </div>
            <!-- (click)="openCasino('EZU-baccarat','100')" -->
            <div class="card" (click)="openCasino('', '92038')">
              <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/baccarat.png"
                class="card-img-top" alt="..." />
              <div class="button"><a>Baccarat</a></div>
            </div>
          </div>
          <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">
            <div class="card" (click)="openCasino('', '98789')">
              <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/lucky7eu.jpg"
                class="card-img-top" alt="..." />
              <div class="button"><a>lucky7</a></div>
            </div>

            <div class="card" (click)="openCasino('', '56968')">
              <img src="https://m2.fawk.app/assets/images/Games/new/Hi%20Low.png" class="card-img-top" alt="..." />
              <div class="button"><a>HIGH LOW</a></div>
            </div>

            <div class="card" (click)="openCasino('', '67564')">
              <img src="https://m2.fawk.app/assets/images/Games/new/Poker.png" class="card-img-top" alt="..." />
              <div class="button"><a>POKER</a></div>
            </div>
            <div class="card" (click)="openCasino('', '67630')">
              <img src="https://m2.fawk.app/assets/images/Games/new/Teenpatti%20Test.png" class="card-img-top"
                alt="..." />
              <div class="button"><a>TEENPATTI TEST</a></div>
            </div>
          </div>
          <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">
            <div class="card" (click)="openCasino('', '56967')">
              <img src="https://m2.fawk.app/assets/images/Games/new/32%20cards%20casino.png" class="card-img-top"
                alt="..." />
              <div class="button"><a>32 CARD CASINO</a></div>
            </div>

            <div class="card" (click)="openCasino('', '92037')">
              <img src="https://m2.fawk.app/assets/images/Games/new/Worli%20Matka.png" class="card-img-top" alt="..." />
              <div class="button"><a>WORLI MATKA</a></div>
            </div>

            <div class="card" (click)="openCasino('', '67640 ')">
              <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/teenpatti.jpg"
                class="card-img-top" alt="..." />
              <div class="button"><a>Teenpatti Open</a></div>
            </div>
            <div class="card" (click)="openCasino('', '67567')">
              <img src="https://m2.fawk.app/assets/images/Games/new/Poker%2020-20.png" class="card-img-top" alt="..." />
              <div class="button"><a>POKER 20-20</a></div>
            </div>
          </div>
          <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">
            <div class="card" (click)="openCasino('', '67690')">
              <img src="https://m2.fawk.app/assets/images/Games/new/29%20Card%20Baccarat.png" class="card-img-top"
                alt="..." />
              <div class="button"><a>29 CARD BACCARAT</a></div>
            </div>

            <div class="card" (click)="openCasino('', '67600')">
              <img src="https://m2.fawk.app/assets/images/Games/new/Muflis%20Teenpatti.png" class="card-img-top"
                alt="..." />
              <div class="button"><a>MUFLIS TEENPATTI</a></div>
            </div>

            <div class="card" (click)="openCasino('', '67710')">
              <img src="https://m2.fawk.app/assets/images/Games/new/Race%20to%2017.png" class="card-img-top"
                alt="..." />
              <div class="button"><a>RACE TO 17</a></div>
            </div>
            <div class="card" (click)="openCasino('', '67610')">
              <img src="https://m2.fawk.app/assets/images/Games/new/Trio.png" class="card-img-top" alt="..." />
              <div class="button"><a>TRIO</a></div>
            </div>
          </div>
          <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">
            <div class="card" (click)="openCasino('', '67680')">
              <img src="https://m2.fawk.app/assets/images/Games/new/The%20Trap.png" class="card-img-top" alt="..." />
              <div class="button"><a>THE TRAP</a></div>
            </div>

            <div class="card" (click)="openCasino('', '87564')">
              <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/andar-bahar.jpg"
                class="card-img-top" alt="..." />
              <div class="button"><a>Andar Bahar</a></div>
            </div>
            <div class="card" (click)="openCasino('', '67565')">
              <img src="https://m2.fawk.app/assets/images/Games/new/Six%20player%20poker.png" class="card-img-top"
                alt="..." />
              <div class="button"><a>SIX PLAYER POKER</a></div>
            </div>
            <div class="card" (click)="openCasino('', '67575')">
              <img src="https://m2.fawk.app/assets/images/Games/new/Casino%20Meter.png" class="card-img-top"
                alt="..." />
              <div class="button"><a>CASINO METER</a></div>
            </div>
          </div>
          <div class="d-flex card-group" style="padding: 0px 5px 0px 5px">
            <div class="card" (click)="openCasino('', '67580')">
              <img src="https://m2.fawk.app/assets/images/Games/new/Casino%20War.png" class="card-img-top" alt="..." />
              <div class="button"><a>CASINO WAR</a></div>
            </div>

            <div class="card" (click)="openCasino('', '98791')">
              <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/aaa.jpg"
                class="card-img-top" alt="..." />
              <div class="button"><a>Amar Akbar Anthony</a></div>
            </div>

            <div class="card" (click)="openCasino('', '67570')">
              <img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/casinoicons/img/bollywood-casino.jpg"
                class="card-img-top" alt="..." />
              <div class="button"><a>Bollywood Casino</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <app-b-footer></app-b-footer>
</ng-container>

<!--welcome_modal -->
<ng-template #dashModal1>
  <div id="dashModal">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title h4 text-white">Beware Of Phishing Websites Before Login. Enable Security Auth To Secure
          Your ID.</div>
        <button style="top: 10px !important" type="button" (click)="modalRef.hide()" class="close">
          <span aria-hidden="true" style="color: #fff">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <img src="https://sitethemedata.com/common/wel-banner/wel-1710609628874.png" alt=""
          style="width:100%;height:100vh;object-fit: cover;" class="mobRes">
        <img src="https://sitethemedata.com/common/wel-banner/wel-1710609649298.png" alt=""
          style="width:100%;height:auto;object-fit: contain;" class="pcRes">
      </div>
    </div>
  </div>
</ng-template>