// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // live_billion
  // userServerUrl: "https://api.billionexch.com/api/",
  // adminServerUrl_manager: "https://adminapi.billionexch.com/api/",
  // SOCKET_ENDPOINT: "https://socket.billionexch.com/",
  // casino_operatorId:"9469",
  // header_logo:'diamond444'

  // diamond_live
  // userServerUrl: "https://api.up365.co.in/api/",
  // adminServerUrl: "https://adminsocket.up365.co.in/api/",
  // SOCKET_ENDPOINT: "https://api.up365.co.in/",
  // ADMIN_SOCKET_ENDPOINT: "https://adminsocket.up365.co.in/",
  // SCORE_SOCKET_ENDPOINT: "https://paisaenapi.paisaexch.com/",
  // casino_operatorId: "9503",

  userServerUrl: "https://api.lionsports.icu/api/",
  adminServerUrl: "https://admin.lionsports.icu/api/",
  SOCKET_ENDPOINT: "https://api.lionsports.icu/",
  ADMIN_SOCKET_ENDPOINT: "https://admin.lionsports.icu/",
  SCORE_SOCKET_ENDPOINT: "https://api.lionsports.icu/",
  casino_operatorId: "96901",
  header_logo: "diamond222",

  // staging_billion
  // userServerUrl: "http://165.22.223.230:3099/api/",
  // adminServerUrl: "http://165.22.223.230:6003/api/",
  // SOCKET_ENDPOINT: "http://165.22.223.230:3099",
  // ADMIN_SOCKET_ENDPOINT: "http://165.22.223.230:6003",
  // SCORE_SOCKET_ENDPOINT: "https://paisaenapi.paisaexch.com/",
  // casino_operatorId: "9468",
  // header_logo: 'diamond222'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
